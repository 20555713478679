import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Accordion, AccordionPanel } from '../../components/Accordion';

const WrapperPanelText = styled.div`
    margin: 0;
    p {
        margin-bottom: 0.5rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 0.25rem;
    }
`;

const renderContent = (edges, totalCount) => {
    if (totalCount === 0) {
        return <div>No changelog.</div>;
    }
    return (
        <Accordion multiple={true} activeIndex={0}>
            {edges.map(({ node: { body, frontmatter } }, index) => {
                // Properties from Gatsby markdown frontmatter
                const { changes = 0, date = '', version = '' } = frontmatter;

                const heading = `${version} - Release Date ${date}`;
                const headingExtra = `${changes} Change${changes > 1 ? 's' : ''}`;
                return (
                    <AccordionPanel key={index} heading={heading} headingExtra={headingExtra}>
                        <WrapperPanelText>
                            <MDXRenderer>{body}</MDXRenderer>
                        </WrapperPanelText>
                    </AccordionPanel>
                );
            })}
        </Accordion>
    );
};

const ChangeLog = (props) => {
    const {
        allMdx: { edges, totalCount },
    } = props.data;

    return (
        <div className="changelog">
            <h1>Changelog</h1>
            {renderContent(edges, totalCount)}
        </div>
    );
};

export default ChangeLog;

/**
 * Query the `changelog` (type) mdx files, and release date will be sorted in DESC order.
 * The query will return a `data` object which will be available for further use in this same file.
 */
export const changelogMdxQuery = graphql`
    query {
        allMdx(
            filter: { frontmatter: { type: { eq: "changelog" } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            totalCount
            edges {
                node {
                    frontmatter {
                        version
                        date(formatString: "DD MMMM YYYY")
                        changes
                    }
                    body
                }
            }
        }
    }
`;
