import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import { AccordionContext } from './Accordion';

const Wrapper = styled.div`
    margin-bottom: 24px;
    border: 1px solid ${({ theme }) => theme?.colors?.grey[100]};
    border-radius: 6px;
`;

const WrapperHeading = styled.div`
    color: ${({ theme }) => theme?.colors?.black};
    background: ${(props) => (props.bg ? props.bg : ({ theme }) => theme?.colors?.lightGreen)};
    padding: 24px 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-family: ${({ theme }) => theme?.fonts?.heading};
`;

const StyledHeading = styled.div`
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.25;
`;

const WrapperHeadingRight = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const StyledDescription = styled.div`
    color: ${(props) => (props.color ? props.color : ({ theme }) => theme?.colors?.black)};
    transition: opacity 0.8s ease, visibility 0.8s ease;
    overflow: hidden;

    ${(props) =>
        props.active
            ? css`
                  visibility: visible;
                  padding: 24px 32px;
                  opacity: 1;
                  max-height: 600px;
              `
            : css`
                  visibility: hidden;
                  padding: 0;
                  opacity: 0;
                  max-height: 0;
              `};
`;

const StyledHeadingExtra = styled.div`
    margin-right: 4px;
    color: ${({ theme }) => theme?.colors?.grey[500]};
    font-size: 0.875rem;
    font-style: italic;
`;

const WrapperArrowSvg = styled.div`
    transform: ${(props) => (props.active ? `rotate(180deg)` : `rotate(0deg)`)};
    transition: transform 0.3s ease-in;
`;

/**
 * Usages: <AccordionPanel key={0} heading={"Your Heading"} headingExtra={"on Top Right"}>Content Description</AccordionPanel>
 * Add it inside <Accordion />,
 */
const AccordionPanel = ({ heading, headingExtra, bg, color, children, ...rest }) => {
    const { onPanelChange, active = false } = useContext(AccordionContext);

    return (
        <Wrapper {...rest}>
            <WrapperHeading bg={bg} onClick={onPanelChange} className="accordion-panel-wrapper-heading">
                <StyledHeading>{heading}</StyledHeading>
                <WrapperHeadingRight>
                    {headingExtra && <StyledHeadingExtra>{headingExtra}</StyledHeadingExtra>}
                    <WrapperArrowSvg active={active} className="arrow-svg">
                        <svg
                            viewBox="0 0 24 24"
                            height="32"
                            width="32"
                            aria-hidden="true"
                            focusable="false"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path>
                        </svg>
                    </WrapperArrowSvg>
                </WrapperHeadingRight>
            </WrapperHeading>
            <StyledDescription color={color} active={active} className="accordion-panel-description">
                {children}
            </StyledDescription>
        </Wrapper>
    );
};

export { AccordionPanel };
