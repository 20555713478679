import React, { Children, useState, useCallback } from 'react';
import { AccordionContext } from './AccordionContext';

/**
 * Usages: <Accordion />, and add <AccordionPanel /> to the Accordion component
 * Available props: multiple and activeIndex, i.e. <Accordion multiple={true} activeIndex={0} />
 * multiple: default true. Able to expand all panels when it is true. Otherwise, only expand one panel
 * activeIndex: expand the specified panel when the component is mounted
 */
const Accordion = ({ multiple = true, activeIndex, children, ...rest }) => {
    // The initial AccordionPanel active indexes
    const initActiveIndexes = typeof activeIndex !== 'undefined' ? [activeIndex] : [];

    const [activeIndexes, setActiveIndexes] = useState(initActiveIndexes);

    const panelChange = (index) => {
        let newIndexes = [...activeIndexes];

        // Check if the element is already in the list
        const isIncluded = newIndexes.indexOf(index) > -1;

        if (multiple) {
            if (isIncluded) {
                newIndexes = newIndexes.filter((i) => i !== index);
            } else {
                newIndexes = [...newIndexes, index];
            }
        } else {
            if (isIncluded) {
                newIndexes = [];
            } else {
                newIndexes = [index];
            }
        }

        // Make elements unique
        newIndexes = Array.from(new Set(newIndexes));

        // Set the new state for the activeIndexes of AccordionPanel
        setActiveIndexes(newIndexes);
    };

    const onPanelChangeCallback = useCallback(panelChange, [activeIndexes]);

    return (
        <div {...rest}>
            {Children.toArray(children).map((child, index) => (
                <AccordionContext.Provider
                    key={index}
                    value={{
                        active: activeIndexes.indexOf(index) > -1,
                        onPanelChange: () => onPanelChangeCallback(index),
                    }}>
                    {child}
                </AccordionContext.Provider>
            ))}
        </div>
    );
};

export { Accordion, AccordionContext };
